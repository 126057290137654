export const IS_AUTHENTICATED_REQUEST = "IS_AUTHENTICATED_REQUEST";
export const IS_AUTHENTICATED_SUCCESS = "IS_AUTHENTICATED_SUCCESS";
export const SKIP_AUTH_CHECK = "SKIP_AUTH_CHECK";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const LOGOUT = "LOGOUT";
